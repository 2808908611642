import React from "react"
import PropTypes from "prop-types"
import { InView } from 'react-intersection-observer';
import classNames from "classnames";

import { getImage } from "gatsby-plugin-image"


const LogItem = ({ setImage, setVisibleProject, slug, data, first, last }) => {

  const { title, description, client, collaborators, magnitude } = data

  return (
    <InView
      rootMargin={first === true ? "-50% 0% 9999% 0%" : last === true ? "9999% 0% -50% 0%" : "-50%"}
      onChange={(inView) => {
        if(inView) {
          setImage({ data: getImage(data.featuredImage), alt: title})
          setVisibleProject(title)
        }
      }}>
      {({ inView, ref }) => (
        <article id={slug} ref={ref} className="container mx-auto z-20" data-mag={magnitude}>
          <div
            className={classNames('max-w-prose py-32 md:py-16 lg:py-8 lg:transition-spacing',
              {
                'lg:pt-10': inView,
              })}>
            <h4
              className={classNames('py-2 inline-block transform origin-bottom-left transition-transform',
                {
                  'lg:scale-125 text-stellar2': inView,
                  'lg:scale-100 text-white opacity-60': !inView
                })}>
              {title}
            </h4>
            <div className={classNames('block', { 'lg:block' : inView, 'lg:hidden' : !inView})}>
              {description && <p>{description}</p>}
              {client && <p>{client}</p>}
              {collaborators && <p>{collaborators}</p>}
            </div>
          </div>
        </article>
      )}
    </InView>
  )
}

LogItem.propTypes = {
  slug: PropTypes.string,
  data: PropTypes.object,
}

export default LogItem