import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import LogItem from "./log-item";
import useTrackingScript from '../hooks/useTrackingScript'

import { GatsbyImage } from "gatsby-plugin-image"


const LogList = ({ limit }) => {

  const data = useStaticQuery(graphql`
    query LogQuery {
      allMarkdownRemark(
        filter: {frontmatter: {hide: {ne: true}}}
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            id
            excerpt
            fields {
              slug
            }
            frontmatter {
              date(formatString: "YYYY-MM-DD")
              title
              description
              client
              collaborators
              url
              tags
              status
              magnitude
              featuredImage {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH, 
                    quality: 90
                    )
                }
              }
            }
          }
        }
      }
    }
  `);

  const posts = data.allMarkdownRemark.edges.slice(0, limit);
  const [image, setImage] = useState(null)
  const [visibleProject, setVisibleProject] = useState(null)

  const status = useTrackingScript('https://microanalytics.io/js/script.js', 'ZwSg9rf6GA')

  useEffect(()=> {

    if(visibleProject && status === 'ready') {
      //tracking is loaded
      if(typeof window !== "undefined") {
        try {
          window.pa.track({name: visibleProject})
        } catch (err) {
          // console.log(err)
        }
      }  
    }

  },[status, visibleProject])

  return (
    <div className="relative">

      <div className="absolute inset-0 flex justify-end">
        {image && (
          <div className="sticky top-0 h-screen w-full lg:w-1/2 opacity-40 lg:opacity-100">
            <GatsbyImage image={image.data} alt={image.alt} className="h-full" />
          </div>
        )}
      </div>
      <div className="relative z-30 pt-screen50 pb-screen50">
        {posts.map(({ node }, index) => {
          return (
            <LogItem key={node.id} slug={node.fields.slug} data={node.frontmatter} setImage={setImage} first={index === 0} last={index === posts.length - 1} setVisibleProject={setVisibleProject} />
          )
        })}
      </div>
    </div>
  )
}

LogList.defaultProps = {
  limit: 9999999,
  reduced: false,
}

LogList.propTypes = {
  limit: PropTypes.number,
  reduced: PropTypes.bool,
}

export default LogList