import React from "react"
import Seo from "../components/seo"
import LogList from "../components/log-list"

const IndexPage = () => {

  return (
    <>

      <Seo
        title=""
        description={`Supernova Design is a creative studio specialising in design & development for web, mobile, and interactive projects`}
      />

      <section id="projects" className="relative py-0 bg-gray-900">
        <div className="absolute inset-0 top-16 z-20">
          <div className="container uppercase">
            <h2 className="mb-8">Projects</h2>
          </div>
        </div>
        <LogList></LogList>
      </section>

      <section id="contact" className="min-h-screen60">
        <div className="container">
          <h3 className="mb-8 uppercase">Get in touch</h3>
          <p>Please contact Ben Stevens with all enquiries <br />
          <a href="mailto:hello@supernova.me">hello@supernova.me</a></p>
        </div>
      </section>
    </>
  )
}

export default IndexPage